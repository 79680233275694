<template>
  <Auth />
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h5 class="mb-0">Pengguna Login</h5>
            <p class="mb-0 text-sm">Daftar pengguna yang mengakses aplikasi.</p>
          </div>
          <div class="card-body" style="border-top: 1px solid #eeeeee">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6 text-end">
                <div class="btn-group" role="group">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="showAddForm"
                  >
                    Tambah Data
                  </button>
                  <div class="dropdown" style="margin-left: 0px">
                    <button
                      id="dropdownMenuButton1"
                      style="border-radius: 0px 5px 5px 0px"
                      class="btn btn-sm btn-dark dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a class="dropdown-item" @click="refreshTable"
                          >Refresh Tabel</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
              </div>
            </div>
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  v-model="pagination.perPage"
                  class="select-primary pagination-select"
                  placeholder="Per page"
                >
                  <el-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    class="select-primary"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div>
                  <el-input
                    v-model="searchQuery"
                    type="search"
                    class="mb-3"
                    clearable
                    prefix-icon="el-icon-search"
                    style="width: 200px"
                    placeholder="Search records"
                    aria-controls="datatables"
                  >
                  </el-input>
                </div>
              </div>
              <div class="table-responsive">
                <el-table
                  :data="queriedData"
                  row-key="id"
                  header-row-class-name="thead-light"
                  @selection-change="selectionChange"
                >
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    v-bind="column"
                  >
                  </el-table-column>
                  <el-table-column align="left" label="Fullname">
                    <template #default="props">
                      <small>
                        <b>{{ props.row.fullname }}</b
                        >&nbsp;<small class="text-secondary"
                          >({{ props.row.level }})</small
                        ><br />
                        <small class="text-secondary">
                          {{ props.row.email }}<br />
                          {{ props.row.phone }}
                        </small>
                      </small>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Created at"
                  >
                    <template #default="props">
                      <small class="text-secondary">
                        {{ props.row.display_created_at }}<br />
                        <b>({{ props.row.total_days }})</b>
                      </small>
                    </template>
                  </el-table-column>

                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Cashback"
                  >
                    <template #default="props">
                      <small class="text-secondary">
                        {{ props.row.cashback_program_name_percentage }}
                      </small>
                    </template>
                  </el-table-column>

                  <el-table-column align="left" label="Login">
                    <template #default="props">
                      <span v-if="props.row.datauser == ''">
                        <small>
                          <a
                            href="#"
                            class="text-primary text-sm"
                            @click.prevent="
                              showCreateLogin(props.$index, props.row)
                            "
                            ><i>buat user login</i></a
                          >
                        </small>
                      </span>
                      <span v-else>
                        <span v-if="props.row.datauser.active == '1'">
                          <argon-badge
                            size="sm"
                            variant="gradient"
                            color="success"
                          >
                            active
                          </argon-badge>
                        </span>
                        <span v-if="props.row.datauser.active == '0'">
                          <argon-badge
                            size="sm"
                            variant="gradient"
                            color="danger"
                          >
                            banned
                          </argon-badge>
                        </span>
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    min-width="100px"
                    align="left"
                    label="Actions"
                  >
                    <template #default="props">
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Snoop"
                        placement="top-start"
                      >
                        <a
                          href="#"
                          @click.prevent="handleSnoop(props.$index, props.row)"
                        >
                          <i class="fas fa-eye text-primary"></i>
                        </a>
                      </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Edit data"
                        placement="top-start"
                      >
                        <a
                          class="mx-3"
                          href="#"
                          @click.prevent="showEditForm(props.$index, props.row)"
                        >
                          <i class="fas fa-user-edit text-primary"></i>
                        </a>
                      </el-tooltip>
                      <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Hapus data"
                        placement="top-start"
                      >
                        <a
                          href="#"
                          @click.prevent="handleDelete(props.$index, props.row)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <br />
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination
                  v-model="pagination.currentPage"
                  class="pagination-no-border"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <h5>Data Form Pengguna Login</h5>
      <form @submit.prevent="onSubmit">
        <input v-model="form.id" type="hidden" />
        <div class="row">
          <div class="col-md-3">
            <label class="form-label">User Profile</label>
            <select v-model="form.user_profile_id" class="form-select" required>
              <option
                v-for="select in selects.user_profiles"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
          <div class="col-md-5">
            <label class="form-label">Nama Lengkap</label>
            <input
              v-model="form.fullname"
              type="text"
              class="form-control"
              required
            />
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>
          <div class="col-md-4">
            <label class="form-label">Jenis Kelamin</label>
            <select v-model="form.gender" class="form-select" required>
              <option
                v-for="select in selects.genders"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="form-label">Alamat</label>
            <input v-model="form.address" type="text" class="form-control" />
          </div>
          <div class="col-md-6">
            <label class="form-label">Kodepos</label>
            <input v-model="form.postalcode" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="form-label">Propinsi</label>
            <select
              v-model="form.province_id"
              class="form-select"
              required
              @change="handleSelectCity"
            >
              <option
                v-for="select in selects.provinces"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>
          <div class="col-md-6">
            <label class="form-label">Kota/Kabupaten</label>
            <select
              v-model="form.city_id"
              class="form-select"
              required
              @change="handleSelectDistrict"
            >
              <option
                v-for="select in selects.cities"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <label class="form-label">Kecamatan</label>
            <select
              v-model="form.district_id"
              class="form-select"
              required
              @change="handleSelectSubDistrict"
            >
              <option
                v-for="select in selects.districts"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>
          <div class="col-md-6">
            <label class="form-label">Kelurahan</label>
            <select v-model="form.subdistrict_id" class="form-select" required>
              <option
                v-for="select in selects.subdistricts"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <label class="form-label">Email</label>
            <input
              v-model="form.email"
              type="email"
              class="form-control"
              required
            />
            <small
              ><span class="form-text"><i>* kolom wajib diisi</i></span></small
            >
          </div>

          <div class="col-md-4">
            <label class="form-label">No Handphone</label>
            <input v-model="form.phone" type="text" class="form-control" />
          </div>

          <div class="col-md-4">
            <button
              v-if="showUpdateLoginLink"
              type="button"
              class="btn btn-link"
              style="margin-top: 35px"
              @click.prevent="showUpdateLogin(form)"
            >
              Update Login
            </button>
          </div>
        </div>

        <hr style="margin-bottom: -20px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Submit Data</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 35px"
          @click="modals.classic = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>

  <modal v-model:show="modals.classic2">
    <div class="p-2">
      <h5>Data Form Pengguna Login</h5>
      <form @submit.prevent="onSubmitLogin">
        <input v-model="form2.id" type="hidden" />
        <input v-model="form2.member_id" type="hidden" />
        <div class="row">
          <div class="col-md-12">
            <label class="form-label">Level</label>
            <select v-model="form2.level" class="form-select" required>
              <option
                v-for="select in selects.levels"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
            <small
              ><span class="form-text"><i>* wajib diisi</i></span></small
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="form-label">Email</label>
            <input v-model="form2.email" type="text" class="form-control" />
            <small
              ><span class="form-text"><i>* wajib diisi</i></span></small
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="form-label">Password</label>
            <input
              v-model="form2.password"
              type="password"
              class="form-control"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="form-label">Ulangi Password</label>
            <input
              v-model="form2.retypepassword"
              type="password"
              class="form-control"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="form-label">Status Aktif</label>
            <select v-model="form2.active" class="form-select">
              <option
                v-for="select in selects.actives"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
        </div>

        <hr style="margin-bottom: -20px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Submit Data</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 35px"
          @click="modals.classic2 = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>

  <modal v-model:show="modals.classic3">
    <div class="p-2">
      <h5>do you have access?</h5>
      <p>sign in with credentials (Administrator)</p>
      Email: <b>{{ snoopUser.email }}</b
      ><br />
      Name: <b>{{ snoopUser.fullname }}</b>
      <form @submit.prevent="onSubmitSnoop" class="mt-4">
        <input v-model="form3.user_id" type="hidden" />
        <input v-model="form3.user_email" type="hidden" />
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="form-label">Your Email</label>
            <input v-model="form3.email" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <label class="form-label">Your Password</label>
            <input
              v-model="form3.password"
              type="password"
              class="form-control"
            />
          </div>
        </div>

        <hr style="margin-bottom: -10px" />
        <argon-button
          color="primary"
          variant="gradient"
          class="my-4 mb-2"
          :loading="isLoading"
          >Check Access</argon-button
        >
        &nbsp;
        <button
          type="button"
          class="btn btn-link"
          style="margin-top: 25px"
          @click="modals.classic3 = false"
        >
          Close
        </button>
      </form>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Auth from "@/components/auth/Auth";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonBadge from "@/components/ArgonBadge.vue";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElLoading,
  ElTooltip,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "Users",
  components: {
    BasePagination,
    Auth,
    ArgonButton,
    ArgonBadge,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElTooltip,
    Modal,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
        classic3: false,
      },
      url_user_profile_select: "/api/v1/member/user_profile/select",
      urlread: "/api/v1/user/read",
      urlstore: "/api/v1/user/store",
      urlupdate: "/api/v1/user/update",
      urldelete: "/api/v1/user/delete",
      urlcreatelogin: "/api/v1/user/createlogin",
      urlupdatelogin: "/api/v1/user/updatelogin",
      urlsnoop: "/api/v1/user/access_snoop",
      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "fullname",
        "email",
        "level",
        "display_created_at",
        "total_days",
      ],
      tableColumns: [
        {
          type: "selection",
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,
      showUpdateLoginLink: false,

      selects: {
        genders: [
          {
            value: "Laki-laki",
            name: "Laki-laki",
          },
          {
            value: "Perempuan",
            name: "Perempuan",
          },
        ],
        levels: [
          {
            value: "Administrator",
            name: "Administrator",
          },
          {
            value: "Member",
            name: "Member",
          },
        ],
        actives: [
          {
            value: "1",
            name: "Active",
          },
          {
            value: "0",
            name: "Banned",
          },
        ],
        provinces: [],
        cities: [],
        districts: [],
        subdistricts: [],
        user_profiles: [],
      },

      form: {
        id: "",
        user_profile_id: "",
        nik: "",
        fullname: "",
        gender: "",
        address: "",
        postalcode: "",
        province_id: "",
        city_id: "",
        district_id: "",
        subdistrict_id: "",
        phone: "",
        email: "",
      },
      form2: {
        id: "",
        member_id: "",
        level: "",
        email: "",
        password: "",
        retypepassword: "",
        active: "",
      },
      form3: {
        user_id: "",
        user_email: "",
        email: "",
        password: "",
      },
      snoopUser: [],
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;
    },
  },
  created() {
    this.getTables();
    this.getProvinceSelect();
    this.getUserProfileSelect();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getUserProfileSelect() {
      this.loadingService();
      try {
        const response = await axios.get(this.url_user_profile_select, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.user_profiles = response.data.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getProvinceSelect() {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.provinces = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getCitySelect(province_id) {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/city/select/" + province_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.cities = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getDistrictSelect(city_id) {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/district/select/" + city_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.districts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getSubDistrictSelect(district_id) {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/subdistrict/select/" + district_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.selects.subdistricts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getTables() {
      try {
        this.loadingService();
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.datas = response.data.datatables;
        this.tableData = this.datas;
        //console.log(this.datas);
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    showAddForm() {
      this.modals.classic = true;
      this.clearForm();
      this.showUpdateLoginLink = false;
    },
    async showEditForm(index, row) {
      this.modals.classic = true;
      this.clearForm();
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url: this.urlread + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        let data = response.data;
        for (let x in this.form) {
          this.form[x] = data[x];
        }
        this.getCitySelect(this.form.province_id);
        this.getDistrictSelect(this.form.city_id);
        this.getSubDistrictSelect(this.form.district_id);

        this.loadingService().close();
        if (row.datauser != "") {
          this.showUpdateLoginLink = true;
          this.form2.id = row.datauser["id"];
          this.form2.level = row.datauser["level"];
          this.form2.active = row.datauser["active"];
        } else {
          this.showUpdateLoginLink = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    showCreateLogin(index, row) {
      this.modals.classic2 = true;
      this.clearForm2();
      this.form2.member_id = row.id;
      this.form2.email = row.email;
    },
    showUpdateLogin(form) {
      console.log(form);
      this.modals.classic = false;
      this.modals.classic2 = true;
      //this.clearForm2();
      this.form2.member_id = form.id;
      this.form2.email = form.email;
    },
    refreshTable() {
      this.getTables();
    },
    clearForm() {
      for (let x in this.form) {
        this.form[x] = "";
      }
    },
    clearForm2() {
      for (let x in this.form2) {
        this.form2[x] = "";
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        let sendurl = this.urlstore;
        if (this.form.id != "") {
          // update
          sendmethod = "put";
          sendurl = this.urlupdate;
        }
        const response = await axios({
          method: sendmethod,
          url: sendurl,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire("Good job!", "Data berhasil disimpan!", "success");
          // load table
          this.getTables();
          // close modal
          this.modals.classic = false;
          // clear form
          this.clearForm();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    async onSubmitLogin() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        let sendurl = this.urlcreatelogin;
        if (this.form2.id != "") {
          // update
          sendmethod = "put";
          sendurl = this.urlupdatelogin;
        }
        if (this.form2.password != this.form2.retypepassword) {
          Swal.fire(
            "Warning!",
            "Password tidak sama. Mohon periksa kembali password dan retype password",
            "warning"
          );
          this.isLoading = false;
        } else {
          const response = await axios({
            method: sendmethod,
            url: sendurl,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            data: this.form2,
          });
          //console.log(response.data);
          this.isLoading = false;
          if (response.data.status == "error") {
            Swal.fire("Warning!", response.data.message, "warning");
          } else if (response.data.status == "success") {
            Swal.fire("Good job!", "Data berhasil disimpan!", "success");
            // load table
            this.getTables();
            // close modal
            this.modals.classic2 = false;
            // clear form
            this.clearForm2();
          } else {
            Swal.fire(
              "Warning!",
              "Terjadi kesalahan proses, mohon refresh halaman.",
              "warning"
            );
          }
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "Konfirmasi!",
        html:
          `Yakin ingin menghapus data ini!<br /><b>` + row.fullname + `</b>`,
        showCancelButton: true,
        confirmButtonText: "Yes, hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteRow(row);
        }
      });
    },
    async deleteRow(row) {
      this.loadingService();
      try {
        const response = await axios({
          method: "delete",
          url: this.urldelete + "/" + row.id,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data);
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          let indexToDelete = this.tableData.findIndex(
            (tableRow) => tableRow.id === row.id
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          Swal.fire("Good job!", "Data berhasil dihapus!", "success");
        }
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    handleSelectCity() {
      this.getCitySelect(this.form.province_id);
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.city_id);
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.district_id);
    },
    handleSnoop(index, row) {
      this.snoopUser = row;
      this.form3.user_id = row.user_id;
      this.form3.user_email = row.email;
      this.modals.classic3 = true;
    },
    async onSubmitSnoop() {
      this.isLoading = true;
      try {
        let sendmethod = "post";
        const response = await axios({
          method: sendmethod,
          url: this.urlsnoop,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form3,
        });
        //console.log(response.data);
        this.isLoading = false;
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          this.isLoading = true;
          // save response
          let dataAuth = JSON.stringify(response.data.auth);
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          localStorage.setItem("auth", dataAuth);

          //redirect
          window.location.href = this.$hostname + "/dashboards";
          //this.$router.push({ path: "/dashboards" });
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.errorRedirect500(error);
      }
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
